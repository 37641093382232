<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: FeedConsumptionCard.vue
Description:This file gives the information of feed consumption according to the selected Date range
-->
<template>
  <er-card
    class="feedconsumption-count-card"
    v-loading="computedLoading"
    element-loading-background="white"
    ref="feed-consumption-count-card"
  >
    <el-row
      align="middle"
      v-show="!computedLoading"
      slot="header"
      type="flex"
      justify="start"
      :class="`feedconsumption-card-count__header ${selectedFeedPeriod}`"
    >
      <img class="card-icon" src="@/assets/dashboard/feedconsumption.svg" />
      <el-col tag="span" :span="12" class="card-title">{{
        $t("Comn_feed_consumption")
      }}</el-col>
      <el-col tag="div" :span="6" class="card-title">
        <el-switch
          v-model="feedAreaSwitch"
          active-color="#0A2463"
          :active-text="getLabelByUserPreferences"
          @change="handleChangeSwitch"
        >
        </el-switch>
      </el-col>
      <el-col
        tag="div"
        :span="7"
        :class="`feed-stat ${feedPeriodList[selectedFeedPeriod].css_class}`"
      >
        <el-col tag="p" class="feed-stat__value">
          <el-popover
            v-model="popoverClose"
            popper-class="fcc-compare-feed-popper"
          >
            <el-row class="bottom-margin small-text font-primary-color">{{
              $t("compare_total_dispensed")
            }}</el-row>
            <er-date-range-filter2
              :value="dateRanges"
              :displayFormat="upm__getFormatDateString"
              :timeZoneString="upm__getFormatTimeString"
              @change="handleDateRangeQuery"
              @cancel="handleCancelDateRangeQuery"
            >
              <template slot="range_1_value">
                <p
                  class="range-value-width"
                  v-upm__units.digitPrecision.truncateThousandStr="
                    getDispenseValueInitial
                  "
                ></p>
              </template>
              <template slot="range_2_value">
                <p
                  class="range-value-width"
                  v-upm__units.digitPrecision.truncateThousandStr="
                    getDispenseValueSecondary
                  "
                ></p>
              </template>
            </er-date-range-filter2>
            <el-col slot="reference">
              <el-tag
                size="small"
                :type="getHourlyFeedGrowthStat >= 0 ? 'primary' : 'danger'"
              >
                <i class="el-icon-loading" v-show="loadingForPercentage"></i>
                <el-row
                  type="flex"
                  v-show="!loadingForPercentage"
                  align="middle filter-popover-trigger"
                >
                  <p type="text">{{ getHourlyFeedGrowthStat }}%</p>
                  <el-divider direction="vertical"></el-divider>
                  <i class="el-icon-date"></i>
                </el-row>
              </el-tag>
            </el-col>
          </el-popover>
        </el-col>
      </el-col>
    </el-row>

    <div style="display: flex" class="highcharts_space">
      <er-select
        size="mini"
        v-model="selectedFeedPeriod"
        collapse-tags
        :showSelectAll="false"
        @change="handleChangeInSelectedPonds"
        :placeholder="$t('Comn_select')"
      >
        <el-option
          v-for="(obj, type) in feedPeriodList"
          :key="type"
          :label="$t(obj.title)"
          :value="type"
        ></el-option>
      </er-select>
    </div>

    <el-row v-show="!computedLoading">
      <high-charts
        :options="feedAreaSwitch ? graphFeedAreaOptions : graphOptions"
        :key="selectedFeedPeriod"
        ref="highcharts"
        constructor-type="stockChart"
      ></high-charts>
    </el-row>
  </er-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { feedConsumptionConfig } from "../utils/chartOptions";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import resizeContainerMixin from "@/mixins/resizeContainerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
// import highChartsTranslationMixin from "@/mixins/highChartsTranslationMixin";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import dateUtils from "@/utils/dateUtils";
export default {
  name: "FeedConsumptionCard",
  mixins: [
    errorHandlerMixin,
    filtersMixin,
    resizeContainerMixin,
    // highChartsTranslationMixin,
    userPreferenceMixin,
    datesHandlerMixin
  ],
  props: ["isLoading"],
  data: function() {
    return {
      query: {
        location_id: null
        // type: "daily"
      },
      feedAreaSwitch: false,
      selectedFeedPeriod: "daily",
      feedPeriodList: {
        daily: {
          css_class: "feed-stat--daily",
          title: "daily",
          color: "#155DBE",
          date_units: "days",
          isSameComparator: "isSameDay",
          range_1: 1,
          range_2: 2,
          chartColors: {
            highlight_bar_color: "#155DBE",
            light_color: "#cbe6f6",
            dark_color: "#2D9CDB"
          }
        },
        weekly: {
          css_class: "feed-stat--weekly",
          title: "Comn_weekly",
          color: "#D39F00",
          date_units: "weeks",
          isSameComparator: "isSameISOWeek",
          range_1: 1,
          range_2: 2,
          chartColors: {
            highlight_bar_color: "#D39F00",
            light_color: "#fcefc9",
            dark_color: "#F2C94C"
          }
        },
        monthly: {
          // period_type: "monthly",
          css_class: "feed-stat--monthly",
          title: "Comn_monthly",
          color: "#6FCF97",
          date_units: "months",
          isSameComparator: "isSameMonth",
          range_1: 1,
          range_2: 2,
          chartColors: {
            highlight_bar_color: "#3e9f66",
            light_color: "#dbf3e5",
            dark_color: "#6FCF97"
          }
        }
      },
      loading: false,
      popoverClose: false,
      dateRanges: [
        [new Date(), new Date()],
        [new Date(), new Date()]
      ],
      loadingForPercentage: false,
      dateFormat: "YYYY-MM-DD",
      dateFormatObject: {
        "dd/MM/yyyy": "%d/%m/%Y",
        "dd MMM": "%d %b",
        "dd MMM, yy": "%d %b, %y"
      }
    };
  },
  watch: {
    async isLoading(newValue, oldValue) {
      if (!newValue) {
        await this.handleDateRangeQuery(this.getDefaultDateRanges);
      }
    },
    async selectedFeedPeriod(newVal, oldVal) {
      await this.initComponent();
    }
  },
  computed: {
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation",
      getPreferredUnits: "getPreferredUnits"
    }),
    ...mapGetters("dashboard", {
      getFeedConsumptionData: "getFeedConsumptionData",
      getFeedConsumptionGrowth: "getFeedConsumptionGrowth"
    }),
    getDispenseValueInitial() {
      const pondSizeAndUnits = {
        value: this.getRangeFeedDispensedValues[0],
        previousunits: this.upm__backendUnits.feed_units,
        units: this.upm__feedUnits,
        modifiersParams: {
          digitPrecision: [0],
          truncateThousandStr: [0],
          formatEmpty: ["-"]
        }
      };
      return pondSizeAndUnits;
    },
    getDispenseValueSecondary() {
      const pondSizeAndUnits = {
        value: this.getRangeFeedDispensedValues[1],
        previousunits: this.upm__backendUnits.feed_units,
        units: this.upm__feedUnits,
        modifiersParams: {
          digitPrecision: [0],
          truncateThousandStr: [0],
          formatEmpty: ["-"]
        }
      };
      return pondSizeAndUnits;
    },
    getFeedAreaKeyByUserPreferences() {
      const areaToKeyMap = {
        ac: "feed_per_acre",
        ha: "feed_per_hectare"
      };
      return areaToKeyMap[this.upm__getPondSizeUnits];
    },
    getLabelByUserPreferences() {
      const areaToKeyMap = {
        ac: "Kg/Ac",
        ha: "Kg/Ha"
      };
      return areaToKeyMap[this.upm__getPondSizeUnits];
    },
    computedLoading() {
      return this.loading || this.isLoading;
    },
    getScreenResolution() {
      return window.innerWidth;
    },
    getRangeFeedDispensedValues() {
      if (!this.getFeedConsumptionGrowth) {
        return [];
      }
      return [
        this.getFeedConsumptionGrowth.range1,
        this.getFeedConsumptionGrowth.range2
      ].map((x) => x && x.feed_values.total_dispensed_feed);
    },
    getTrendImg() {
      if (this.getHourlyFeedGrowthStat > 0) {
        return require(`@/assets/dashboard/upTrend.svg`);
      } else if (this.getHourlyFeedGrowthStat < 0) {
        return require(`@/assets/dashboard/downTrend.svg`);
      }
      return "";
    },
    getDefaultDateRanges() {
      const selectedPeriod = this.feedPeriodList[this.selectedFeedPeriod];
      const dateUnits = selectedPeriod.date_units;
      const format = "yyyy-MM-dd";
      const unitsToStartOf = {
        days: "startOfDay",
        weeks: { fn: "startOfWeek", params: { weekStartsOn: 1 } },
        months: "startOfMonth"
      };
      const unitsToEndOf = {
        days: "endOfDay",
        weeks: { fn: "endOfWeek", params: { weekStartsOn: 1 } },
        months: "endOfMonth"
      };
      const dateInstance = () =>
        this.dhm__dateUtilsLib.endOfDay(
          this.dhm__dateUtilsLib.toDate(this.dhm__getTodayInUserTZ)
        );
      const endDayGenerator = (units) => [
        this.dhm__dateUtilsLib.subtract(dateInstance(), { [units]: 1 }),
        this.dhm__dateUtilsLib.subtract(dateInstance(), { [units]: 2 })
      ];
      const formatFn = (date, format) =>
        this.dhm__dateUtilsLib.formatDate(date, format);
      const startOf = (date, units) => {
        if (unitsToStartOf[units].params) {
          return this.dhm__dateUtilsLib[unitsToStartOf[units].fn](
            date,
            unitsToStartOf[units].params
          );
        }
        return this.dhm__dateUtilsLib[unitsToStartOf[units]](date);
      };
      const endOf = (date, units) => {
        if (unitsToEndOf[units].params) {
          return this.dhm__dateUtilsLib[unitsToEndOf[units].fn](
            date,
            unitsToStartOf[units].params
          );
        }
        return this.dhm__dateUtilsLib[unitsToEndOf[units]](date);
      };
      const rangeGenerator = (units) => {
        const endDays = endDayGenerator(units);
        return endDays.map((x) => [
          formatFn(startOf(x, units), format),
          formatFn(endOf(x, units), format)
        ]);
      };
      return rangeGenerator(dateUnits);
    },
    getResponsiveChartHeight() {
      if (window.innerWidth >= 1920) {
        return "260px";
      } else if (window.innerWidth >= 1366) {
        return "230px";
      } else {
        return "200px";
      }
    },

    graphOptions() {
      const feedData = [];
      const dFeedData = [];
      // Chart configuration
      const getDispFeedPercent = (dispFeed, totalFeed) => {
        return Math.round((dispFeed * 100) / totalFeed);
      };
      const chOptions = feedConsumptionConfig.chart_options;
      chOptions.chart.height = this.getResponsiveChartHeight;
      const selectedFeedPeriod = this.selectedFeedPeriod;
      chOptions.xAxis.tickPositioner = function() {
        let positions = [];
        console.log("positions 0", positions);
        if (selectedFeedPeriod !== "monthly") {
          console.log("positions 1", positions);
          positions = [];
          let tick = Math.floor(this.dataMin);
          const intervals = selectedFeedPeriod === "daily" ? 9 : 4;
          const increment = Math.ceil(
            (this.dataMax - this.dataMin) / intervals
          );
          console.log(
            this.dataMax,
            this.dataMin,
            this.dataMax - this.dataMin,
            increment
          );
          if (this.dataMax !== null && this.dataMin !== null) {
            for (tick; tick - increment <= this.dataMax; tick += increment) {
              positions.push(tick);
            }
          }
        } else {
          // positions = [];
          // const first_tick = dateUtils.startOfMonth(
          //   new Date(this.dataMin)
          // );
          // const first_tick = dateUtils.startOfMonth(this.dataMin);
          // const intervals = selectedFeedPeriod === "daily" ? 9 : 4;
          // const increment = Math.ceil(
          //   (this.dataMax - this.dataMin) / intervals
          // );
          if (this.dataMax !== null && this.dataMin !== null) {
            // for (tick; tick - increment <= this.dataMax; tick += increment) {
            //   positions.push(tick);
            // }
            const resArr = [
              this.dataMin,
              dateUtils.addMonths(this.dataMin, 1).getTime(),
              dateUtils.addMonths(this.dataMin, 2).getTime(),
              dateUtils.addMonths(this.dataMin, 3).getTime(),
              dateUtils.addMonths(this.dataMin, 4).getTime()
            ];
            positions = resArr;
          }
        }
        return positions;
      };
      chOptions.xAxis.tickAmount = this.selectedFeedPeriod === "daily" ? 10 : 5;
      chOptions.xAxis.startOfWeek = 0;
      chOptions.tooltip.xDateFormat = this.dateFormatObject[
        this.upm__getFormatDateString
      ];
      let isCurrent = false;
      // Creating two serie for the stacked column - percentage mode
      if (!this.getFeedConsumptionData) {
        return {};
      }
      this.getFeedConsumptionData.forEach(
        ({ dispensed_feed, feed, date }, index) => {
          const objselecteddate = this.feedPeriodList[selectedFeedPeriod];
          isCurrent = this.dhm__dateUtilsLib[objselecteddate.isSameComparator](
            this.dhm__getTodayInUserTZ,
            this.dhm__dateUtilsLib.castBrowserDateToUserUTC(date, {
              timeZone: this.getUserTimeZoneString
            })
          );
          feedData[index] = {
            x: this.dhm__dateUtilsLib.getTime(
              this.dhm__dateUtilsLib.parseISO(date)
            ),
            y: feed - dispensed_feed,
            color: this.feedPeriodList[selectedFeedPeriod].chartColors
              .light_color
          };
          dFeedData[index] = {
            x: this.dhm__dateUtilsLib.getTime(
              this.dhm__dateUtilsLib.parseISO(date)
            ),
            dateFormat: this.dhm__formatOnDateObjWithLocale(
              this.dhm__dateUtilsLib.parse(
                date,
                "yyyy-MM-dd'T'HH:mm:ss'Z'",
                new Date()
              ),
              this.upm__getFormatDateString
            ),
            y: dispensed_feed,
            feed_value: feed,
            color: isCurrent
              ? this.feedPeriodList[selectedFeedPeriod].chartColors
                  .highlight_bar_color
              : this.feedPeriodList[selectedFeedPeriod].chartColors.dark_color,
            dispense_feed_percentage: getDispFeedPercent(dispensed_feed, feed),
            feedTrans: this.$t("Comn_feed"),
            dispensedFeedTrans: this.$t("PM_dispensed_feed"),
            units: this.getPreferredUnits.feed_units
          };
        }
      );

      const series = [
        {
          name: this.$t("PM_day_feed"),
          data: feedData
        },
        {
          name: this.$t("PM_dispensed"),
          data: dFeedData
        }
      ];

      return {
        ...feedConsumptionConfig.chart_options,
        series
      };
    },
    graphFeedAreaOptions() {
      const dFeedData = [];
      // Chart configuration
      const chOptions = feedConsumptionConfig.chart_options_feed_per_area;
      chOptions.chart.height = this.getResponsiveChartHeight;
      const selectedFeedPeriod = this.selectedFeedPeriod;
      chOptions.xAxis.tickPositioner = function() {
        let positions = [];
        if (selectedFeedPeriod !== "monthly") {
          let tick = Math.floor(this.dataMin);
          const intervals = selectedFeedPeriod === "daily" ? 9 : 4;
          const increment = Math.ceil(
            (this.dataMax - this.dataMin) / intervals
          );

          if (this.dataMax !== null && this.dataMin !== null) {
            for (tick; tick - increment <= this.dataMax; tick += increment) {
              positions.push(tick);
            }
          }
        } else {
          // positions = [];
          // const first_tick = dateUtils.startOfMonth(
          //   new Date(this.dataMin)
          // );
          // const first_tick = dateUtils.startOfMonth(this.dataMin);
          // const intervals = selectedFeedPeriod === "daily" ? 9 : 4;
          // const increment = Math.ceil(
          //   (this.dataMax - this.dataMin) / intervals
          // );
          if (this.dataMax !== null && this.dataMin !== null) {
            // for (tick; tick - increment <= this.dataMax; tick += increment) {
            //   positions.push(tick);
            // }
            const resArr = [
              this.dataMin,
              dateUtils.addMonths(this.dataMin, 1).getTime(),
              dateUtils.addMonths(this.dataMin, 2).getTime(),
              dateUtils.addMonths(this.dataMin, 3).getTime(),
              dateUtils.addMonths(this.dataMin, 4).getTime()
            ];
            positions = resArr;
          }
        }
        return positions;
      };
      chOptions.xAxis.tickAmount = this.selectedFeedPeriod === "daily" ? 10 : 5;
      chOptions.xAxis.startOfWeek = 0;
      chOptions.tooltip.xDateFormat = this.dateFormatObject[
        this.upm__getFormatDateString
      ];
      let isCurrent = false;
      // Creating two serie for the stacked column - percentage mode
      if (!this.getFeedConsumptionData) {
        return {};
      }
      this.getFeedConsumptionData.forEach((dataObj, index) => {
        const objselecteddate = this.feedPeriodList[selectedFeedPeriod];
        isCurrent = this.dhm__dateUtilsLib[objselecteddate.isSameComparator](
          this.dhm__getTodayInUserTZ,
          this.dhm__dateUtilsLib.castBrowserDateToUserUTC(dataObj.date, {
            timeZone: this.getUserTimeZoneString
          })
        );
        dFeedData[index] = {
          x: this.dhm__dateUtilsLib.getTime(
            this.dhm__dateUtilsLib.parseISO(dataObj.date)
          ),
          dateFormat: this.dhm__formatOnDateObjWithLocale(
            this.dhm__dateUtilsLib.parse(
              dataObj.date,
              "yyyy-MM-dd'T'HH:mm:ss'Z'",
              new Date()
            ),
            this.upm__getFormatDateString
          ),
          y: dataObj[this.getFeedAreaKeyByUserPreferences],
          label: this.$t("feed-area"),
          color: isCurrent
            ? this.feedPeriodList[selectedFeedPeriod].chartColors
                .highlight_bar_color
            : this.feedPeriodList[selectedFeedPeriod].chartColors.dark_color,
          dispensedFeedTrans: this.$t("PM_dispensed_feed"),
          units: this.getPreferredUnits.feed_units
        };
      });
      const series = [
        {
          name: this.$t("PM_dispensed"),
          data: dFeedData
        }
      ];

      return {
        ...feedConsumptionConfig.chart_options_feed_per_area,
        series
      };
    },
    getHourlyFeedGrowthStat() {
      if (!this.getFeedConsumptionGrowth) {
        return 0;
      }
      const { range1, range2 } = this.getFeedConsumptionGrowth;
      if (!range1 || !range2) return 0;
      const totalDispensed1 = range1.feed_values.total_dispensed_feed;
      const totalDispensed2 = range2.feed_values.total_dispensed_feed;
      if (!totalDispensed2) return 0;
      // Considering yesterdays as orginal
      if (totalDispensed1 > 0) {
        const change = totalDispensed1 - totalDispensed2;
        return Math.round((change / totalDispensed2) * 100);
      } else {
        return 0;
      }
    }
  },
  async mounted() {
    this.selectedFeedPeriod = "daily";
    this.rcm__registerResizeObserver(
      "feed-consumption-count-card",
      this.rcm__debounce(100, () => {
        if (this.$refs.highcharts) {
          this.$refs.highcharts.chart.reflow();
        }
      })
    );
  },
  async beforeDestroy() {
    // await this.changeFeedPeriod("daily");
  },
  methods: {
    ...mapActions("dashboard", {
      fetchFeedConsumptionData: "fetchFeedConsumptionData",
      fetchHourlyFeedGrowthStat: "fetchHourlyFeedGrowthStat",
      changeFeedPeriod: "changeFeedPeriod",
      fetchFeedConsumptionGrowth: "fetchFeedConsumptionGrowth"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    async initComponent() {
      try {
        this.loading = true;
        this.query.location_id =
          this.getCurrUserLocation && this.getCurrUserLocation._id;
        await Promise.all([this.fetchFeedConsumptionData(this.query)]);
      } catch (err) {
        console.log(err);
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
        await this.handleDateRangeQuery();
      }
    },
    async handleChangeInSelectedPonds() {
      this.mixPanelEventGenerator({ eventName: "Dashboard - Feed Consumption - Dropdown" });
      await this.changeFeedPeriod(this.selectedFeedPeriod);
    },
    handleChangeSwitch() {
      this.mixPanelEventGenerator({ eventName: "Dashboard - Feed Consumption - Kg/Ac Toggle" });
    },
    async handleDateRangeQuery(value) {
      this.closePopOver();
      try {
        const dateRanges = value || this.getDefaultDateRanges;
        this.dateRanges = dateRanges;
        this.loadingForPercentage = true;
        const queryFeedConsumptionGrowth = {
          location_id: this.getCurrUserLocation && this.getCurrUserLocation._id,
          range1: {
            start_date: dateRanges[0][0],
            end_date: dateRanges[0][1]
          },
          range2: {
            start_date: dateRanges[1][0],
            end_date: dateRanges[1][1]
          }
        };
        await this.fetchFeedConsumptionGrowth(queryFeedConsumptionGrowth);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loadingForPercentage = false;
      }
    },
    closePopOver() {
      this.popoverClose = false;
    },
    handleCancelDateRangeQuery(value) {
      // this.initComponent();
      this.closePopOver();
    },
    getDispFeedPercent(dispFeed, totalFeed) {
      return Math.round((dispFeed * 100) / totalFeed);
    }
  }
};
</script>

<style lang="scss">
.range-value-width {
  width: 37px;
  display: flex;
  justify-content: center;
}
.el-range-separator {
  width: auto !important;
}
@mixin component-font-size {
  @include responsiveProperty(
    font-size,
    $app_font_size_1,
    $app_font_size_2,
    $app_font_size_3
  );
}
.el-switch__label {
  span {
    color: #0a2463 !important;
  }
}
.feedconsumption-count-card {
  display: flex;
  flex-direction: column;
  // 5px 10px 10px 10px;
  .page_loading {
    @include responsiveProperty(height, 40vh, 40vh, 40vh);
    width: 100%;
    padding: 0px;
    margin: 0px;
    background-color: white;
    text-align: center;
  }
  .el-card__header {
    // line-height: 1.4;
    @include responsiveProperty(padding-bottom, 0px, 5px, 10px);
  }
  .feedconsumption-card-count__header {
    display: flex;
    align-items: center;
    @include responsiveProperty(line-height, 1.8, 1.8, 1.8);
    .header-stats {
      font-size: 8px;
      background: #233a73;
      border-radius: 5px;
      display: flex;
      padding: 5px;
      color: white;
      .value {
        font-size: 13px;
      }
    }
    .weekly {
      background-color: #d39f00;
    }
    .daily {
      background-color: #155dbe;
    }
    .monthly {
      background-color: greenyellow;
    }
  }

  // highchart styling
  $stroke-color: $highcharts-axis-stroke-color;
  .highcharts-axis-title {
    fill: $highcharts-axis-title-color !important;
    @include highcharts-axis-title-font-size;
  }
  .highcharts-xaxis {
    .highcharts-axis-line {
      stroke: $stroke-color;
    }
    .highcharts-tick {
      stroke: $stroke-color;
    }
  }

  .highcharts-xaxis-labels {
    &.highcharts-axis-labels text {
      color: $stroke-color !important;
      @include highcharts-axis-labels-font-size;
      fill: $stroke-color !important;
    }
  }
  .highcharts-yaxis-labels {
    &.highcharts-axis-labels text {
      color: $stroke-color !important;
      @include highcharts-yaxis-labels-font-size;
      fill: $stroke-color !important;
    }
  }
  .highcharts-tooltip {
    span {
      @include highcharts-tooltip-font-size;
    }
  }
  // charts scroll bar styling
  .highcharts-scrollbar-thumb {
    fill: $stroke-color;
    stroke: $stroke-color;
    opacity: 0.5;
    stroke-width: 1px;
  }

  .highcharts-scrollbar-button {
    fill: #ffffff;
    stroke: #00000066;
    stroke-width: 1px;
  }

  .highcharts-scrollbar-arrow {
    fill: $stroke-color;
  }

  .highcharts-scrollbar-rifles {
    stroke: #ffffff;
    stroke-width: 1px;
  }

  .highcharts-scrollbar-track {
    fill: #f2f2f2;
    stroke: #00000066;
    stroke-width: 1px;
  }

  .el-card__body {
    display: flex;
    padding: 5px 10px 0px 10px !important;
    flex-direction: column;
    justify-content: center;
    .el-select {
      width: 21%;
      .el-input__inner {
        border: 0px;
        border-radius: unset;
        border-bottom: 0.5px solid #1f204166;
        color: #1f2041;
        @include component-font-size;
        // line-height: 20px;
        // height: 20px;
        padding-left: 0px;
        padding-right: 0px;
        @include responsiveProperty(padding-bottom, 0px, 0px, 5px);
      }
      .el-input__icon {
        // line-height: 20px;
        width: 100%;
      }
      .el-select__caret {
        color: #1f2041;
        @include component-font-size;
        font-weight: bolder;
      }
      .el-input--mini .el-input__inner {
        @include responsiveProperty(height, 15px, 18px, 20px);
      }
      .el-input--mini .el-input__icon {
        @include responsiveProperty(line-height, 15px, 18px, 20px);
      }
    }
    .highcharts_space {
      @include responsiveProperty(margin-bottom, 8.5px, 6.8px, 11.5px);
    }
  }

  .feed-stat {
    text-transform: capitalize;
    display: flex;
    align-items: center;
    &--dialy {
      color: #155dbe;
    }
    &--weekly {
      color: #d39f00;
    }
    &--monthly {
      color: greenyellow;
    }
    &__title {
      text-align: right;
      margin-right: 5px;
      @include responsiveProperty(
        font-size,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
    }
    &__value {
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
      .filter-popover-trigger {
        cursor: pointer;
      }
      p {
        @include responsiveProperty(
          font-size,
          $app_font_size_small,
          $app_font_size_1,
          $app_font_size_2
        );
        margin-right: 4px;
        margin-top: auto;
      }
      img {
        @include icon-size(svg, suffix);
      }
    }
  }
}
</style>
