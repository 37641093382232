<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: TemperatureCard.vue
Description:this file shows the Average temperature of  all the ponds
-->
<template>
  <er-card
    class="temperature__card"
    body-style="padding:0;display:flex; height: 100%;"
    v-loading="loading"
    element-loading-background="white"
  >
    <div class="page_loading" v-if="loading"></div>

    <el-col :span="13" class="container container--left" v-show="!loading">
      <el-row class="header__title">{{ $t("Comn_temperature") }}</el-row>
      <el-row type="flex" class="body" style="flex-grow: 1">
        <el-col :span="12" class="icon-holder">
          <img src="@/assets/dashboard/temperature.svg" />
        </el-col>

        <el-col :span="12" class="value-container">
          <el-row type="flex">
            <p v-upm__nounits.digitPrecision="getTempValueUnits"></p>
          </el-row>
          <el-row>
            <p class="averagecontent">{{ $t("average") }}</p>
          </el-row>
        </el-col>
      </el-row>
      <el-row class="footer">
        <!-- <p class="thresoldrange">{{ $t("threshold") }} {{ $t("range") }} [25&#8451;-30&#8451;]</p> -->
      </el-row>
    </el-col>

    <el-col :span="11" class="container container--right" v-show="!loading">
      <div class="container__item">
        <div class="heading__one">
          <p class="heading">{{ $t("with_in_thres") }}</p>
        </div>
        <div class="heading__second">
          <div>
            <img src="@/assets/dashboard/lake.svg" class="icon" />
          </div>
          <div class="threshold__value threshold__value--in">
            <el-button type="text" @click="handlePondsClickIn('IN')">{{
              ftm__formatEmpty(
                getTempSummary ? getTempSummary.with_threshold : undefined
              )
            }}</el-button>
          </div>
          <div class="threshold__value-title">{{ $t("Comn_ponds") }}</div>
        </div>
      </div>

      <div class="container__item">
        <div class="heading__one">
          <p class="heading">{{ $t("out_of_thres") }}</p>
        </div>

        <div class="heading__second">
          <div>
            <img src="@/assets/dashboard/lake.svg" class="icon" />
          </div>

          <div class="threshold__value threshold__value--out">
            <el-button type="text" @click="handlePondsClickOut('OUT')">{{
              ftm__formatEmpty(
                getTempSummary ? getTempSummary.with_out_threshold : undefined
              )
            }}</el-button>
          </div>
          <div class="threshold__value-title">{{ $t("Comn_ponds") }}</div>
        </div>
      </div>
    </el-col>
  </er-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import filtersMixin from "@/mixins/filtersMixin";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import redirectsMixin from "@/mixins/redirectsMixin";

export default {
  name: "TemperatureCard",
  mixins: [filtersMixin, userPreferenceMixin, redirectsMixin],
  props: ["isLoading"],
  data: function () {
    return {
      query: {
        location_id: null
      }
    };
  },
  computed: {
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation",
      getUserCountryCode: "getUserCountryCode",
      getPreferredUnits: "getPreferredUnits"
    }),
    ...mapGetters("dashboard", {
      getTempSummary: "getTempSummary"
    }),
    loading() {
      return this.isLoading;
    },
    getTemperature() {
      if (!this.getTempSummary) return 0;
      return this.getTempSummary.average_temperature;
    },
    getTempValueUnits: function () {
      const temperaturedata = {
        value: this.getTemperature || 0,
        units: this.getPreferredUnits.temperature_units,
        modifiersParams: { digitPrecision: [1] },
        displayFormatter: (param) => {
          return `\u00b0${param}`;
        }
      };
      return temperaturedata;
    }
  },
  methods: {
    ...mapActions("dashboard", {
      fetchTempSummary: "fetchTempSummary",
      savePondsToFilterInPondInfoTab: "savePondsToFilterInPondInfoTab"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    getValueInUserUnits(
      value,
      presentUnits,
      conversionUnits,
      type,
      countryCode
    ) {
      return this.$commonUtils.valueInUserUnits(
        value,
        presentUnits,
        conversionUnits,
        type,
        countryCode
      );
    },
    async handlePondsClickIn(input) {
      if (!this.getTempSummary) {
        return;
      }
      if (this.getTempSummary.with_threshold) {
        this.mixPanelEventGenerator({ eventName: "Dashboard - Pond Hyper Link Event" });
        await this.savePondsToFilterInPondInfoTab({
          card_type: "TEMPERATURE_CARD",
          input
        });
        this.rdm__navigateToPathWithLocationId({
          path: "/user/dashboard",
          query: {
            tab: "pondInfoTab"
          }
        });
      }
    },
    async handlePondsClickOut(input) {
      if (!this.getTempSummary) {
        return;
      }
      if (this.getTempSummary.with_out_threshold) {
        this.mixPanelEventGenerator({ eventName: "Dashboard - Pond Hyper Link Event" });
        await this.savePondsToFilterInPondInfoTab({
          card_type: "TEMPERATURE_CARD",
          input
        });
        this.rdm__navigateToPathWithLocationId({
          path: "/user/dashboard",
          query: {
            tab: "pondInfoTab"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss">
@mixin component-font-size($base_font_size, $offset_value: 0) {
  @include responsiveProperty(
    font-size,
    $base_font_size,
    $base_font_size + (1 * $offset_value),
    $base_font_size + (2 * $offset_value)
  );
}
.temperature__card {
  $columns-padding-top: 15px;
  .page_loading {
    @include responsiveProperty(height, 20vh, 20vh, 20vh);
    width: 100%;
    padding: 0px;
    margin: 0px;
    background-color: white;
    text-align: center;
  }
  .container {
    display: flex;
    flex-direction: column;
    &--left {
      @include responsiveProperty(margin, auto 0px, 10px, 10px);
      padding-left: 10px;
      padding-right: 10px;
      .header__title {
        @include component-font-size($app_font_size_1, 4);
        font-weight: 600;
        color: #0a2463;
        @include responsiveProperty(padding-top, 10px, 0px, 2px);
      }
      .body {
        margin-top: 15px;
      }
      .icon-holder {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          @include responsiveProperty(
            width,
            $app_font_size_1 * 3,
            $app_font_size_2 * 3,
            $app_font_size_3 * 3
          );
          @include responsiveProperty(
            height,
            $app_font_size_1 * 4,
            $app_font_size_2 * 4,
            $app_font_size_3 * 4
          );
        }
      }

      .value-container {
        margin: auto 0px;

        .value {
          display: flex;
          @include component-font-size($app_font_size_xl, 3);
          font-weight: 600;
          line-height: 30px;
          margin-right: 5px;
          color: #1f2041;
          text-transform: uppercase;
        }
        .units {
          vertical-align: super;
          @include component-font-size($app_font_size_1);
          line-height: 20px;
          color: #1f2041;
          font-weight: lighter;
        }
      }
      .averagecontent {
        @include component-font-size($app_font_size_1);
        text-transform: capitalize;
        color: #979797;
      }
      .footer {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        padding: 15px 0px 10px 0px;
      }
      .thresoldrange:before {
        display: inline-block;
        width: 0.4 * $app_font_size_1;
        height: 0.4 * $app_font_size_1;
        background: #33a02c;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 5px;
        content: "";
      }
      .thresoldrange {
        line-height: 11px;
        color: #33a02c;
        @include responsiveProperty(
          font-size,
          $app_font_size_v_small,
          $app_font_size_v_small + 2,
          $app_font_size_v_small + 4
        );
      }
    }
    &--right {
      margin: auto 0px;
      // padding-left: 10px;
      // display: flex;
      // align-items: flex-start;
      .container__item {
        &:nth-child(2) {
          @include responsiveProperty(margin-top, 10px, 25px, 30px);
        }
        .heading__one {
          display: flex;
          align-items: center;
          justify-content: center;
          .heading {
            @include responsiveProperty(
              font-size,
              $app_font_size_v_medium,
              $app_font_size_small,
              $app_font_size_1
            );
          }
        }
        .heading__second {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-evenly;
          .icon {
            width: 24px;
            height: 32px;
          }
          .threshold__value {
            .el-button--text {
              padding: 0px;
              span {
                @include responsiveProperty(
                  font-size,
                  $app_font_size_2,
                  $app_font_size_3,
                  $app_font_size_xl
                );
                font-weight: 600;
              }
            }
            &.threshold__value--in .el-button--text span {
              color: #33a02c;
            }
            &.threshold__value--out .el-button--text span {
              color: #ff393b;
            }
          }
          .threshold__value-title {
            color: #6c7b8a;
            @include responsiveProperty(
              font-size,
              $app_font_size_1,
              $app_font_size_2,
              $app_font_size_3
            );
          }
        }
      }
    }
  }
}
</style>
