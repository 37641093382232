<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: ABWBiomassCard.vue
Description:This file shows the abw vs biomass vs ponds data via  progress Bar.And Additional information biomass/pond area ,total biomass ,total survival rate,shrimps/PondArea.
-->
<template>
  <er-card
    class="abwbiomass-count-card"
    v-loading="loading"
    element-loading-background="white"
    ref="abw-biomass-card"
  >
    <div class="page_loading" v-if="loading"></div>
    <el-row
      v-show="!loading"
      slot="header"
      type="flex"
      justify="start"
      class="abwbiomass-card-count__header"
    >
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        class="card-icon"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.07802 14.5422C5.96989 14.6603 5.96864 14.8409 6.0752 14.9606L8.51677 17.7075C8.57739 17.7756 8.66302 17.8125 8.75052 17.8125C8.7877 17.8125 8.8252 17.8059 8.86145 17.7922C8.9827 17.7459 9.06302 17.6297 9.06302 17.5C9.06302 16.9831 9.48364 16.5625 10.0005 16.5625C10.5174 16.5625 10.938 16.9831 10.938 17.5C10.938 17.6297 11.0183 17.7459 11.1396 17.7922C11.2611 17.8378 11.398 17.8047 11.4843 17.7075L13.9258 14.9603C14.0324 14.8406 14.0311 14.66 13.923 14.5419C12.9183 13.4431 11.4886 12.8125 10.0005 12.8125C8.51208 12.8125 7.08239 13.4431 6.07802 14.5422ZM11.4174 16.8419C11.2383 16.4578 10.9061 16.1606 10.5011 16.0231L11.2058 14.8487L10.6699 14.5272L9.8152 15.9516C9.26864 16.0166 8.80708 16.3622 8.58333 16.8422L6.73489 14.7625C7.01239 14.4934 7.32302 14.2663 7.65364 14.075L8.1777 14.8612L8.6977 14.5147L8.21645 13.7928C8.68208 13.6009 9.17895 13.4875 9.6877 13.4531V14.375H10.3127V13.4528C10.8211 13.4872 11.318 13.6006 11.784 13.7925L11.3027 14.5144L11.8227 14.8609L12.3468 14.0747C12.6774 14.2659 12.988 14.4931 13.2655 14.7622L11.4174 16.8419Z"
          fill="#C2C9D0"
        />
        <path
          d="M15.625 9.0625C16.8309 9.0625 17.8125 8.08094 17.8125 6.875C17.8125 6.35813 17.3919 5.9375 16.875 5.9375H15.7847L12.7306 1.70875C12.0994 0.834375 11.0784 0.3125 10 0.3125C8.92156 0.3125 7.90062 0.834375 7.26937 1.70875L4.21531 5.9375H3.125C2.60812 5.9375 2.1875 6.35813 2.1875 6.875C2.1875 8.08094 3.16906 9.0625 4.375 9.0625H8.75V10.0256C8.045 10.435 7.55906 11.1778 7.5075 12.0372C4.94219 13.0656 3.125 15.6497 3.125 18.5947C3.125 19.1975 3.615 19.6875 4.21781 19.6875H15.7822C16.385 19.6875 16.875 19.1975 16.875 18.5947V18.4375C16.875 16.5159 16.0619 14.6681 14.6447 13.3687C14.0028 12.7803 13.2747 12.3294 12.4916 12.0247C12.4362 11.1703 11.9513 10.4328 11.2497 10.0256V9.0625H15.625ZM7.77594 2.075C8.29031 1.3625 9.12156 0.9375 10 0.9375C10.8784 0.9375 11.7097 1.3625 12.2241 2.075L15.0138 5.9375H4.98625L7.77594 2.075ZM2.8125 6.875C2.8125 6.7025 2.9525 6.5625 3.125 6.5625H16.875C17.0475 6.5625 17.1875 6.7025 17.1875 6.875C17.1875 7.73656 16.4866 8.4375 15.625 8.4375H4.375C3.51344 8.4375 2.8125 7.73656 2.8125 6.875ZM16.25 18.4375V18.5947C16.25 18.8525 16.04 19.0625 15.7822 19.0625H4.21781C3.96 19.0625 3.75 18.8525 3.75 18.5947C3.75 15.2931 6.24906 12.4894 9.43969 12.2119C9.62938 12.1953 9.81875 12.1872 10.0063 12.1872C11.5756 12.1872 13.0556 12.7597 14.2222 13.8294C15.5109 15.0109 16.25 16.6903 16.25 18.4375ZM11.8363 11.8075C11.0538 11.5922 10.2278 11.5156 9.38562 11.5894C8.96563 11.6259 8.55719 11.7044 8.1625 11.8166C8.335 10.9597 9.09312 10.3125 10 10.3125C10.9038 10.3125 11.66 10.955 11.8363 11.8075ZM10.625 9.76969C10.4247 9.71781 10.2163 9.6875 10 9.6875C9.78375 9.6875 9.57531 9.71781 9.375 9.76969V9.0625H10.625V9.76969Z"
          fill="#233A73"
        />
        <path
          d="M15 18.4375C15.3452 18.4375 15.625 18.1577 15.625 17.8125C15.625 17.4673 15.3452 17.1875 15 17.1875C14.6548 17.1875 14.375 17.4673 14.375 17.8125C14.375 18.1577 14.6548 18.4375 15 18.4375Z"
          fill="#D8D8D8"
        />
        <path
          d="M5 18.4375C5.34518 18.4375 5.625 18.1577 5.625 17.8125C5.625 17.4673 5.34518 17.1875 5 17.1875C4.65482 17.1875 4.375 17.4673 4.375 17.8125C4.375 18.1577 4.65482 18.4375 5 18.4375Z"
          fill="#233A73"
        />
        <path
          d="M10.3125 2.1875H10.9375V2.8125H10.3125V2.1875Z"
          fill="#508FF4"
        />
        <path d="M11.5625 3.75H12.1875V4.375H11.5625V3.75Z" fill="#508FF4" />
        <path
          d="M12.8125 4.6875H13.4375V5.3125H12.8125V4.6875Z"
          fill="#508FF4"
        />
        <path d="M8.4375 2.8125H9.0625V3.4375H8.4375V2.8125Z" fill="#508FF4" />
        <path d="M7.1875 4.375H7.8125V5H7.1875V4.375Z" fill="#233A73" />
        <path d="M9.6875 4.0625H10.3125V4.6875H9.6875V4.0625Z" fill="#508FF4" />
        <g clip-path="url(#clip0)">
          <path
            d="M18.4414 18.3167C18.667 18.5132 18.8404 18.7635 18.9429 19.0508H21.8936C21.9608 18.7681 22.0001 18.4748 22.0001 18.1719C22.0001 17.2271 21.6522 16.3635 21.0805 15.6971L18.4414 18.3167Z"
            fill="#155DBE"
          />
          <path
            d="M14.3633 20.5352C14.3633 20.3732 14.2322 20.2422 14.0703 20.2422C13.9084 20.2422 13.7773 20.3732 13.7773 20.5352C13.7773 21.3428 14.4345 22 15.2422 22C15.3542 22 18.2937 22 18.1719 22C19.7529 22 21.131 21.0116 21.7062 19.6367H19.0508C19.0508 20.606 18.2623 21.4141 17.293 21.4141H16.4141C16.2524 21.4141 16.1211 21.2827 16.1211 21.1211C16.1211 20.9592 15.9901 20.8281 15.8281 20.8281C15.6662 20.8281 15.5352 20.9592 15.5352 21.1211C15.5352 21.2243 15.5563 21.322 15.5891 21.4141H15.2422C14.7575 21.4141 14.3633 21.0198 14.3633 20.5352Z"
            fill="#155DBE"
          />
          <path
            d="M17.293 17.4537C17.3183 17.6201 17.37 17.7556 17.4493 17.8945C17.6157 17.9106 17.7772 17.9434 17.9275 18.002L20.6662 15.2828C19.9998 14.7111 19.1166 14.3438 18.1719 14.3438H17.293V17.4537Z"
            fill="#155DBE"
          />
          <path
            d="M12.5872 14.6303C12.5872 14.6326 12.5859 14.6344 12.5859 14.6367C12.5859 15.6353 13.0621 16.5486 13.7773 17.1402V17.3516H13.7782C13.7804 17.9524 14.0242 18.5401 14.4491 18.965C14.5635 19.0794 14.7489 19.0794 14.8634 18.965C14.9778 18.8505 14.9778 18.6651 14.8634 18.5507C14.5905 18.2779 14.4257 17.9139 14.3822 17.5323C14.5766 17.6304 14.782 17.7087 14.9963 17.7661C15.0817 18.2171 15.2954 18.6394 15.621 18.965C15.7354 19.0794 15.9208 19.0794 16.0353 18.965C16.1497 18.8505 16.1497 18.6651 16.0353 18.5507C15.8444 18.3596 15.7045 18.1236 15.6215 17.8684C15.6902 17.8728 15.7584 17.8789 15.8281 17.8789H16.1918C16.2905 18.287 16.4946 18.6667 16.7929 18.9649C16.9073 19.0794 17.0927 19.0794 17.2071 18.9649C17.3216 18.8505 17.3216 18.6651 17.2071 18.5507C16.7906 18.1342 16.7578 17.7033 16.707 17.5121V14.3438H13.4648C13.3032 14.3438 13.1719 14.2124 13.1719 14.0508C13.1719 13.8891 13.3032 13.7578 13.4648 13.7578H17C17.1619 13.7578 17.293 13.6268 17.293 13.4648C17.293 13.3029 17.1619 13.1719 17 13.1719H13.4648C13.0834 13.1719 12.7613 13.4175 12.6399 13.7578C12.6071 13.6657 12.5859 13.568 12.5859 13.4648C12.5859 12.9802 12.9802 12.5859 13.4648 12.5859H17.5859C17.7479 12.5859 17.8789 12.4549 17.8789 12.293C17.8789 12.131 17.7479 12 17.5859 12H13.4648C12.6572 12 12 12.6572 12 13.4648C12 13.9421 12.2329 14.3627 12.5872 14.6303ZM15.2422 15.5352C15.404 15.5352 15.5352 15.6663 15.5352 15.8281C15.5352 15.9899 15.404 16.1211 15.2422 16.1211C15.0804 16.1211 14.9492 15.9899 14.9492 15.8281C14.9492 15.6663 15.0804 15.5352 15.2422 15.5352Z"
            fill="#155DBE"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect
              width="10"
              height="10"
              fill="white"
              transform="translate(12 12)"
            />
          </clipPath>
        </defs>
      </svg>
      <el-col :span="8" class="abwbiomass-count__header__total card-title">
        {{ $t("abw_vs_biomass") }}
      </el-col>
      <layout-toolbar class="abw-biomass-stats" justify="end">
        <div class="area_container">
          <el-tooltip
            popperClass="abwbiomass-count-card-el-tooltip__popper"
            effect="dark"
            :content="$t('Comn_biomass_pond_area')"
            placement="bottom"
          >
            <div
              v-upm__units.truncateThousandStr="getBiomassVsAreaAndUnits"
            ></div>
          </el-tooltip>
        </div>

        <div class="area_container">
          <el-tooltip
            popperClass="abwbiomass-count-card-el-tooltip__popper"
            effect="dark"
            :content="$t('Comn_total_biomass')"
            placement="bottom"
          >
            <div
              v-upm__units.truncateThousandStr="getTotalBiomassAndUnits"
            ></div>
          </el-tooltip>
        </div>

        <div class="area_container">
          <el-tooltip
            popperClass="abwbiomass-count-card-el-tooltip__popper"
            effect="dark"
            :content="$t('Comn_survival_percentage')"
            placement="bottom"
          >
            <div
              v-upm__nounits.digitPrecision="getTotalSurvivalRateAndUnits"
            ></div>
          </el-tooltip>
        </div>

        <div class="area_container">
          <el-tooltip
            popperClass="abwbiomass-count-card-el-tooltip__popper"
            effect="dark"
            :content="$t('Comn_Shrimps_pond_area')"
            placement="bottom"
          >
            <div
              v-upm__nounits.formatLakhStr="getTotalPlsStockedAndUnits"
            ></div>
          </el-tooltip>
        </div>
      </layout-toolbar>
    </el-row>
    <el-row v-if="getSeries.length > 0" v-show="!loading">
      <el-col :span="5" class="col-auxiliary-title">{{ $t("ABW_abw") }}</el-col>
      <el-col :span="19" class="col-auxiliary-title_label">
        <div>{{ $t("Comn_biomass") }}</div>
        <div>{{ $t("Comn_ponds") }}</div>
      </el-col>
    </el-row>
    <el-row class="chart-container" v-show="!loading">
      <el-row class="chart-container__grid">
        <div class="grid-line horizontal">
          <!-- <span class="count-label svelte-bw547y">40,000 </span> -->
        </div>
      </el-row>
      <el-row class="chart-container__inner" v-if="getSeries.length > 0">
        <!-- <hr class="axis axis--y" /> -->
        <el-col
          class="chart-container__row"
          v-for="range in getSeries"
          :key="range.name"
        >
          <el-col :span="5" class="row__label-container">
            <p class="row-label">{{ range.label }}</p>
            <span class="row-sub-label">{{
              $tc(getPreferredUnits.abw_and_growth_units, 2)
            }}</span>
          </el-col>
          <el-col :span="19" class="row__bar-container">
            <div class="row__bar__ponds__container">
              <div>
                <el-row class="bar">
                  <div class="bar__value">
                    <div
                      class="bar__filler"
                      :style="`width:${getWidth(range.total_biomass)}px`"
                    ></div>
                    <p
                      class="bar__title"
                      :data-units="`${getPreferredUnits.biomass_units}`"
                    >
                      {{
                        ftm__formatEmpty(
                          ftm__truncateThousandStr(
                            ftm__digitPrecision(
                              upm__convertUnits(
                                range.total_biomass,
                                upm__backendUnits.biomass_units,
                                getPreferredUnits.biomass_units
                              ),
                              2
                            )
                          )
                        )
                      }}
                    </p>
                  </div>
                </el-row>
              </div>

              <div
                class="col-auxiliary-value"
                @click="handleHyperLinkAbwBiomassPonds(range)"
              >
                {{ range.total_ponds }}
              </div>
            </div>
          </el-col>
        </el-col>

        <el-col class="chart-container__scale" v-if="!loading">
          <el-col
            :offset="5"
            class="scale__row"
            id="scaleDiv"
            ref="scaleContainer"
          >
            <img
              class="chart__line"
              src="@/assets/dashboard/line.svg"
              style="min-width: 10px"
            />
            <p
              v-for="(i, index) in getXAxisInterval"
              :key="i + scaleContainerWidth"
              class="scale__value"
              :style="
                `left:${(scaleContainerWidth * index) /
                  (noOfIntervals + 1)}px; color: #233a73; `
              "
            >
              <span class="value_vertical_line">ı</span>

              {{ ftm__formatEmpty(ftm__truncateThousandStr(i, 0, "ceil")) }}
            </p>
          </el-col>
        </el-col>
      </el-row>

      <el-row class="no-data-container" v-else>{{
        $t("Comn_no_data_connection")
      }}</el-row>
    </el-row>
  </er-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import filtersMixin from "@/mixins/filtersMixin";
import resizeContainerMixin from "@/mixins/resizeContainerMixin";
import unitsHelper from "@/constants/units";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import redirectsMixin from "@/mixins/redirectsMixin";
export default {
  name: "ABWBiomassCard",
  mixins: [
    filtersMixin,
    resizeContainerMixin,
    userPreferenceMixin,
    redirectsMixin
  ],
  props: ["isLoading"],
  data: function() {
    return {
      query: {
        location_id: null
      },
      scaleContainerWidth: 1,
      noOfIntervals: 6
    };
  },
  watch: {
    isLoading() {
      this.$nextTick(() => {
        if (this.$refs.scaleContainer) {
          this.scaleContainerWidth = this.$refs.scaleContainer.$el.offsetWidth;
        }
      });
    }
  },
  mounted() {
    this.rcm__registerResizeObserver(
      "abw-biomass-card",
      this.rcm__debounce(100, () => {
        if (this.$refs.scaleContainer) {
          this.scaleContainerWidth = this.$refs.scaleContainer.$el.offsetWidth;
        }
      })
    );
  },
  beforeDestroy() {
    this.rcm__unregisterResizeObserver("abw-biomass-card", () => {
      if (this.$refs.scaleContainer) {
        this.scaleContainerWidth = this.$refs.scaleContainer.$el.offsetWidth;
      }
    });
  },
  computed: {
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation",
      getUserCountryName: "getUserCountryName",
      getUserCountryCode: "getUserCountryCode",
      getPreferredUnits: "getPreferredUnits"
    }),
    ...mapGetters("dashboard", {
      getAbwVsBiomassData: "getAbwVsBiomassData"
    }),
    loading() {
      return this.isLoading;
    },
    updatePondSizeBasedOnUnits() {
      return (size, units) => {
        // const userLocation = this.getUserCountryName.toLowerCase();
        // if (userLocation === "india") {
        //   return Number(
        //     this.$commonUtils.castPondAreaSize(size, units, "ac")
        //   ).toFixed(2);
        // }
        return Number(
          this.$commonUtils.castPondAreaSize(size, units, this.getPreferredUnits.pond_area_units)
        ).toFixed(2);
      };
    },

    getWidth() {
      return value => {
        const convUnits = this.upm__convertUnits(
          value,
          this.upm__backendUnits.biomass_units,
          this.getPreferredUnits.biomass_units
        );
        const maxOld = this.getMaxXAxisValue;
        const minOld = 0;
        const maxNew = this.scaleContainerWidth - 40;
        const minNew = 0;
        const a = (maxNew - minNew) / (maxOld - minOld);
        const b = maxNew - a * maxOld;
        return a * convUnits + b;
      };
    },
    getMaxXAxisValue() {
      let maxValue = Math.max(
        ...this.getSeries.map(x =>
          this.upm__convertUnits(
            x.total_biomass,
            this.upm__backendUnits.biomass_units,
            this.getPreferredUnits.biomass_units
          )
        )
      );
      if (maxValue % 10) {
        maxValue = maxValue + (10 - (maxValue % 10));
      }
      return isFinite(maxValue) ? maxValue : 1;
    },
    getXAxisInterval() {
      const maxRoundedXAxisValue = this.getMaxXAxisValue;
      const noOfIntervals = this.noOfIntervals;
      const intervalGap = maxRoundedXAxisValue / noOfIntervals;
      const noOfTicks = this.noOfIntervals;
      const arr = [];
      for (let x = 0; x <= noOfTicks; x += 1) {
        arr.push(x * intervalGap);
      }
      return arr.some(x => x > 0) ? arr : [];
    },
    getSeries() {
      if (!this.getABWBiomassData) {
        return [];
      }
      const labels = ["≤ 5", "> 5-10", "> 10-20", "> 20-30", "> 30"];
      const data = this.getABWBiomassData.map(
        ({ name, total_biomass, total_ponds }, index) => {
          const label = labels[index];
          return {
            name,
            total_biomass,
            total_ponds,
            label
          };
        }
      );
      return data;
    },
    getAbwVsBiomassObj() {
      return this.getAbwVsBiomassData || {};
    },
    getTotalBiomass() {
      if (!this.getAbwVsBiomassObj) {
        return 0;
      }
      return this.getAbwVsBiomassObj.total_biomass;
    },
    getBiomassVsArea() {
      if (!this.getAbwVsBiomassObj) {
        return 0;
      }
      const total_biomass = this.getAbwVsBiomassObj.total_biomass;
      let total_pond_size = this.getAbwVsBiomassObj.total_pond_size;
      if (!total_pond_size || !total_biomass) return 0;
      // const userLocation = this.getUserCountryName;
      // if (userLocation === "india") {\
      if (this.getPreferredUnits.pond_area_units === "ac") {
        total_pond_size = this.$commonUtils.castPondAreaSize(
          total_pond_size,
          "ha",
          "ac"
        );
      }
      const comepleteBioAreaUnits = this.getPreferredUnits.biomass_per_area_units.split(
        "/"
      );
      const biomassUnits = comepleteBioAreaUnits[0];
      const pondAreaUnits = comepleteBioAreaUnits[1];

      const stat =
        this.upm__convertUnits(
          total_biomass,
          this.upm__backendUnits.biomass_units,
          biomassUnits
        ) /
        this.upm__convertUnits(
          total_pond_size,
          this.upm__backendUnits.pond_area_units,
          pondAreaUnits
        );
      return Math.round(stat);
    },

    getBiomassVsAreaAndUnits() {
      const biomassVsAreaUnitsData = {
        value: [
          Number(this.getAbwVsBiomassObj.total_biomass) || 0,
          Number(this.getAbwVsBiomassObj.total_pond_size) || 0
        ],
        units: [
          this.getPreferredUnits.biomass_per_area_units.biomass_units.toLowerCase(),
          this.getPreferredUnits.biomass_per_area_units.pond_area_units.toLowerCase()
        ],

        previousunits: [
          this.upm__backendUnits.biomass_per_area_units.biomass_units,
          this.upm__backendUnits.biomass_per_area_units.pond_area_units
        ],
        operation: ([param1, param2]) => {
          if (!param2) return 0;
          return param1 / param2;
        },
        displayFormatter: ([param1, param2]) => {
          return `${param1}/${param2}`;
        },
        ui: {
          unitsOnEmpty: true
        },
        modifiersParams: { truncateThousandStr: [0] }
      };
      return biomassVsAreaUnitsData;
    },
    getTotalBiomassAndUnits() {
      const totalBiomassandUnits = {
        value: Number(this.getTotalBiomass) || 0,
        previousunits: this.upm__backendUnits.biomass_units,
        units: this.getPreferredUnits.biomass_units,
        ui: {
          unitsOnEmpty: true
        },
        modifiersParams: { truncateThousandStr: [2] }
      };
      return totalBiomassandUnits;
    },
    getTotalSurvivalRateAndUnits() {
      const totalsurvivalrateandUnits = {
        value: this.getTotalSurvivalRate || 0,
        units: "%",
        modifiersParams: { digitPrecision: [1] }
      };
      return totalsurvivalrateandUnits;
    },
    getTotalPlsStockedAndUnits() {
      const totalplsstockedandUnits = {
        value: this.getTotalPlsStocked || 0,
        modifiersParams: {
          formatLakhStr: [0]
        },
        units: [
          this.$t("PL_stocked_abbr"),
          this.getPreferredUnits.pond_area_units.toLowerCase()
        ],
        displayFormatter: ([param1, param2]) => {
          return `${param1}/${param2}`;
        }
      };
      return totalplsstockedandUnits;
    },

    getABWBiomassData() {
      if (!this.getAbwVsBiomassObj) {
        return [];
      }
      return this.getAbwVsBiomassObj.abw_range_values;
    },
    getTotalPlsStocked() {
      if (!this.getAbwVsBiomassObj) {
        return 0;
      }
      const allPondSizes = this.upm__convertUnits(
        this.getAbwVsBiomassObj.total_pond_size || 0,
        this.upm__backendUnits.pond_area_units,
        this.getPreferredUnits.pond_area_units
      );
      const data = this.getAbwVsBiomassObj.abw_range_values || [];
      const postlara = data
        .map(x => x.ponds)
        .map(y => y.map(z => z.post_larva_stocked))
        .flat(1);

      const survival_percentage = data
        .map(x => x.ponds)
        .map(y => y.map(z => z.abw_data.survival_percentage))
        .flat(1);

      const pLStocked = postlara.reduce(
        (acc, curr, i) => acc + curr * survival_percentage[i],
        0
      );

      return (
        pLStocked / this.updatePondSizeBasedOnUnits(allPondSizes.toFixed(2))
      );
    },

    getTotalSurvivalRate() {
      if (!this.getAbwVsBiomassObj) {
        return 0;
      }
      const data = this.getAbwVsBiomassObj.abw_range_values || [];

      const postlara = data
        .map(x => x.ponds)
        .map(y => y.map(z => z.post_larva_stocked))
        .flat(1);

      const survivalrate = data
        .map(x => x.ponds)
        .map(y => y.map(z => z.abw_data).map(i => i.survival_percentage))
        .flat(1);

      const survivalmultiply = postlara.reduce(function(r, a, i) {
        return r + a * survivalrate[i];
      }, 0);

      const plsStocked = data
        .map(x => x.ponds)
        .map(y => y.map(z => z.post_larva_stocked))
        .flat(1)
        .reduce(function(accumulator, currentValue) {
          return accumulator + currentValue;
        }, 0);

      const survivalpercentage = survivalmultiply / plsStocked;

      return survivalpercentage;
    }
  },
  methods: {
    ...mapActions("dashboard", {
      abwBiomassHyperlinkData: "abwBiomassHyperlinkData"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    getUserUnits(type) {
      return unitsHelper[
        this.$commonUtils.getUnitType(this.getUserCountryCode)
      ][type].units;
    },
    getValueInUserUnits(
      value,
      presentUnits,
      conversionUnits,
      type,
      countryCode
    ) {
      return this.$commonUtils.valueInUserUnits(
        value,
        presentUnits,
        conversionUnits,
        type,
        countryCode
      );
    },
    async handleHyperLinkAbwBiomassPonds(range) {
      if (range.total_ponds) {
        this.mixPanelEventGenerator({ eventName: "Dashboard - Pond Hyper Link Event" });
        await this.abwBiomassHyperlinkData({
          name: range.name
        });
        this.rdm__navigateToPathWithLocationId({
          path: "/user/dashboard",
          query: {
            tab: "pondInfoTab"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss">
$font-color: #233a73;
$unit-color: #508ff4;
@mixin component-font-size($offset_value: 0) {
  @include responsiveProperty(
    font-size,
    $app_font_size_1 + $offset_value,
    $app_font_size_2 + $offset_value,
    $app_font_size_3 + $offset_value
  );
}
.abwbiomass-count-card {
  .page_loading {
    @include responsiveProperty(height, 40vh, 40vh, 40vh);
    width: 100%;
    padding: 0px;
    margin: 0px;
    background-color: white;
    text-align: center;
  }
  .er-card.er-card--secondary .el-card__body {
    padding: 0px 10px 10px 10px !important;
  }
  .abwbiomass-card-count__header {
    display: flex;
    align-items: center;

    .abw-biomass-stats {
      @include responsiveProperty(
        font-size,
        $app_font_size_v_small,
        $app_font_size_v_small + 1,
        $app_font_size_v_small + 2
      );
      font-weight: 500;
      flex-grow: 2;

      .area_container {
        display: flex;
        flex-direction: column;
        color: #ffffff;
        background: $font-color;

        color: white;
        background: #233a73;
        border-radius: 5px;
        .value {
          @include responsiveProperty(
            font-size,
            $app_font_size_small,
            $app_font_size_1,
            $app_font_size_2
          );
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          flex-direction: column;
          letter-spacing: initial;
          @include responsiveProperty(min-width, 30px, 40px, 40px);
          max-width: max-content;
          padding-inline-start: 4px;
          padding-inline-end: 4px;
          .units {
            display: flex;
            color: #5df953;
            @include responsiveProperty(
              font-size,
              $app_font_size_v_small,
              $app_font_size_v_medium,
              $app_font_size_small
            );
          }
        }
      }

      // p:first-of-type {
      //   margin-right: 4px;
      // }

      .title {
        @include responsiveProperty(
          line-height,
          $app_font_size_1,
          $app_font_size_2,
          $app_font_size_3 + 3
        );
      }
      .value {
        color: #ffffff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        @include responsiveProperty(
          font-size,
          $app_font_size_1,
          $app_font_size_2,
          $app_font_size_3 + 3
        );
        @include responsiveProperty(
          line-height,
          $app_font_size_1,
          $app_font_size_2,
          $app_font_size_3 + 3
        );
      }
      > span:first-child {
        @include responsiveProperty(
          font-size,
          $app_font_size_v_small,
          $app_font_size_v_small + 3,
          $app_font_size_v_small + 5
        );
        background: $font-color;
        border-radius: 5px;
        display: flex;
        padding: 5px;
      }
      > span:nth-of-type(2) {
        @include responsiveProperty(
          font-size,
          $app_font_size_v_small,
          $app_font_size_v_small + 3,
          $app_font_size_v_small + 5
        );
        background: #f54e5e;
        border-radius: 5px;
        display: flex;
        padding: 5px;
      }
    }
  }

  .chart-container {
    &__grid {
      position: absolute;
      height: 90%;
      width: 0px;
      left: 20.8%;
      .grid-line {
        height: calc(100% + 1em);
        border-left: 1px solid #ececec;
      }
    }
    &___inner {
      .axis {
        transform-origin: left;
        @include respond-to(tablet) {
          transform: scaleY(0.4) translate(20.9%, 20px) rotate(90deg);
        }
        @include respond-to(medium-screens) {
          transform: scaleY(0.4) translate(20.9%, 35px) rotate(90deg);
        }
        @include respond-to(wide-screens) {
          transform: scaleY(0.44) translate(20.9%, 35px) rotate(90deg);
        }
        border-color: #ececec;
      }
    }
  }
  .chart-container__row {
    @include responsiveProperty(margin-top, 8px, 9px, 10px);
    @include responsiveProperty(margin-bottom, 8px, 9px, 10px);
    display: flex;
    align-items: center;
    align-content: space-between;
    justify-content: center;
  }
  .row__label-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .row-label {
      @include component-font-size;
      color: $font-color;
    }
    .row-sub-label {
      margin-left: 5px;
      @include responsiveProperty(
        font-size,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
      line-height: 1.6;
      color: $unit-color;
    }
  }
  .row__bar-container {
    .bar {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      color: $font-color;

      &__filler {
        height: 6px;
        @include responsiveProperty(height, 6px, 9px, 12px);
        margin-right: 5px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        background: linear-gradient(225deg, #2d9cdb 0%, #155dbe 100%);
        &:hover {
          background: linear-gradient(45deg, #2d9cdb 0%, #155dbe 100%);
        }
      }
      &__title {
        @include component-font-size;
        &:after {
          content: attr(data-units);
          @include responsiveProperty(
            font-size,
            $app_font_size_small,
            $app_font_size_1,
            $app_font_size_2
          );
          display: inline-block;
          margin-left: 1px;
          color: $unit-color;
        }
      }
      .bar__value {
        display: flex;
        align-items: center;
      }
    }
    .row__bar__ponds__container {
      display: flex;
      justify-content: space-between;
    }
  }
  .col-auxiliary-title_label {
    color: #233a73;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
  }
  .col-auxiliary-title {
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
    color: #233a73;

    font-weight: 600;
  }

  .col-auxiliary-value {
    @include component-font-size;
    color: #33a02c;
    font-weight: 600;
    cursor: pointer;
  }
  .col-pond {
    @include responsiveProperty(padding-right, 0px, 3px, 10px);
  }
  .chart-container__scale {
    // @include responsiveProperty(margin-top, -8.7px, -7px, -3.5px);
    .chart__line {
      // @include responsiveProperty(width,500px,500px,600px)
    }

    // margin-bottom: 15px;
    .scale__row {
      display: flex;
      // margin-top: 10px;
      // @include responsiveProperty(
      //   border-bottom,
      //   1.5px solid #233a73,
      //   2px solid #233a73,
      //   2px solid #233a73
      // );
      @include responsiveProperty(margin-top, 8px, 6.5px, 12px);
      position: relative;
      @include responsiveProperty(
        width,
        calc(100% * calc(15 / 24)),
        calc(100% * calc(14 / 24)),
        calc(100% * calc(14 / 24))
      );
    }
    .scale__value {
      .value_vertical_line {
        display: flex;
        align-items: center;
        justify-content: center;
        @include responsiveProperty(margin-top, -4.5px, -5px, -7px);
        color: #233a73;
      }

      @include responsiveProperty(
        font-size,
        $app_font_size_small - 1,
        $app_font_size_1 - 1,
        $app_font_size_2 - 1
      );
      color: #808080;
      position: absolute;
      &:first-child {
        margin-left: 5px;
      }
    }
  }
  .no-data-container {
    text-align: center;
    margin-top: 20%;
  }
}
.abwbiomass-count-card-el-tooltip__popper.is-dark {
  background: #233a73;
}
.abwbiomass-count-card-el-tooltip__popper {
  padding: 4px;
}
// .abwbiomass-count-card-el-tooltip__popper[x-placement^="bottom"]
//   .popper__arrow::after {
//   background: #233a73;
// }

.abwbiomass-count-card-el-tooltip__popper[x-placement^="bottom"]
  .popper__arrow {
  top: -6px;
  border-top-width: 0;
  border-bottom-color: #233a73;
}
.abwbiomass-count-card-el-tooltip__popper[x-placement^="bottom"]
  .popper__arrow:after {
  border-bottom-color: #233a73;
}
</style>
