<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: PondPerformanceCard.vue
Description:this file give the info about the averagebodyweight with respective to the days
-->
<template>
  <er-card
    class="card-pond-performance er-card--secondary"
    v-loading="loading"
    element-loading-background="white"
  >
    <div class="page_loading" v-if="loading"></div>
    <el-row
      v-show="!loading"
      slot="header"
      type="flex"
      justify="start"
      class="card__header"
      style="display: flex"
    >
      <img
        class="card-icon"
        src="@/assets/dashboard/pondsperformance.svg"
        v-show="!loading"
      />
      <span class="el-col el-col-18 card-title" v-show="!loading">
        {{ $t("ponds_performance") }}
      </span>

      <div class="el-row legend-row" v-show="!loading">
        <div
          class="legend-row__item"
          v-for="(label, key) in legends"
          :key="label"
        >
          <div :class="`color-indicator ${key}`" :key="key">&nbsp;</div>
          <div class="legend-row__label" :key="`label-${key}`">{{ label }}</div>
        </div>
      </div>
    </el-row>
    <el-row
      type="flex"
      class="card-pond-abw"
      justify="space-around"
      v-show="!loading"
    >
      <el-table
        :data="getTableData"
        size="mini"
        class="blue-row-table"
        row-class-name="blue-row-table__row"
        header-row-class-name="blue-row-table__header-row"
        :cell-class-name="tableCellClassName"
      >
        <el-table-column
          v-for="i in tableColumns"
          :label="i.label"
          :key="i.label"
          :align="i.align"
          :prop="i.prop"
          :minWidth="i.minWidth"
        >
          <template slot="header" v-if="i.prop === 'row_header'">
            <div class="row-column-indicator">
              <div>&nbsp;</div>
              <div style="color: #233a73">{{ $t("ABW_abw") }}</div>
              <div style="color: #233a73">DOC</div>
              <div>&nbsp;</div>
            </div>
          </template>
          <template slot="header" v-else>
            <div class="title-section">
              <p class="symbol-holder">{{ i.label_symbol }}</p>
              <div class="title-container">
                <div class="title">{{ i.label }}</div>
                <div class="sub-title">
                  {{ $tc(getPreferredUnits.abw_and_growth_units, 2) }}
                </div>
              </div>
            </div>
          </template>
          <template slot-scope="scope">
            <span
              style="cursor: pointer"
              @click="handlePonds(scope.row[i.prop].ponds)"
              >{{
                ftm__formatEmpty(
                  i.prop === "row_header"
                    ? scope.row[i.prop]
                    : scope.row[i.prop].length,
                  "-"
                )
              }}</span
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </er-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import filtersMixin from "@/mixins/filtersMixin";
import redirectsMixin from "@/mixins/redirectsMixin";

export default {
  name: "PondsPerformanceCard",
  mixins: [filtersMixin, redirectsMixin],
  props: ["isLoading"],
  data: function() {
    return {
      selectedModel: "",
      query: {
        location_id: null
        // type: "daily"
      },
      tableProps: {
        size: "mini"
      },
      abw_model_options: {
        ciba_model: "CIBA"
      }
    };
  },
  computed: {
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation",
      getPreferredUnits: "getPreferredUnits"
    }),
    ...mapGetters("dashboard", {
      getPondPerformance: "getPondPerformance"
    }),
    legends: function() {
      return {
        low: this.$t("Comn_low"),
        normal: this.$t("Comn_normal"),
        high: this.$t("Comn_high")
      };
    },
    tableColumns() {
      return {
        row_header: {
          prop: "row_header",
          label: "row header",
          minWidth: 80,
          align: "center"
        },
        abw_lt_5: {
          prop: "abw_lt_5",
          label: "5",
          label_symbol: "\u2264",
          minWidth: 60,
          align: "center"
        },
        abw_gte_5_lt_10: {
          prop: "abw_gte_5_lt_10",
          label: "5-10",
          label_symbol: ">",
          minWidth: 80,
          align: "center"
        },
        abw_gte_10_lt_20: {
          prop: "abw_gte_10_lt_20",
          label: "10-20",
          label_symbol: ">",
          minWidth: 80,
          align: "center"
        },
        abw_gte_20_lt_30: {
          prop: "abw_gte_20_lt_30",
          label: "20-30",
          label_symbol: ">",
          minWidth: 80,
          align: "center"
        },
        abw_gte_30: {
          prop: "abw_gte_30",
          label: "30",
          label_symbol: ">",
          minWidth: 60,
          align: "center"
        }
      };
    },
    getTableData() {
      const data = [
        { label: "≤ 30" },
        { label: "31-60" },
        { label: "61-90" },
        { label: "91-120" },
        { label: "> 120" }
      ].map((docRange, docIndex) => {
        return [
          "abw_lt_5",
          "abw_gte_5_lt_10",
          "abw_gte_10_lt_20",
          "abw_gte_20_lt_30",
          "abw_gte_30"
        ].reduce(
          (acc, key, abwIndex) => {
            let ponds = [];
            if (this.getPondPerformance && this.getPondPerformance.length > 0) {
              ponds = this.getPondPerformance[docIndex].abw_range[abwIndex]
                .ponds;
            }
            acc[key] = { ponds, length: ponds.length };
            return acc;
          },
          { row_header: docRange.label }
        );
      });

      return data;
    },
    loading() {
      return this.isLoading;
    }
  },
  methods: {
    ...mapActions("dashboard", {
      pondPerformanceHyperlinkData: "pondPerformanceHyperlinkData"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    tableCellClassName({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) return "";
      if (row[column.property] <= 0) return "normal-cell";
      if (rowIndex + 1 < columnIndex) {
        return "high-cell";
      } else if (rowIndex + 1 > columnIndex) {
        return "low-cell";
      } else {
        return "normal-cell";
      }
    },

    async handlePonds(value) {
      if (value.length) {
        await this.pondPerformanceHyperlinkData({
          value: value
        });
        this.mixPanelEventGenerator({ eventName: "Dashboard - Pond Hyper Link Event" });
        this.rdm__navigateToPathWithLocationId({
          path: "/user/dashboard",
          query: {
            tab: "pondInfoTab"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss">
@mixin component-font-size {
  @include responsiveProperty(
    font-size,
    $app_font_size_1,
    $app_font_size_2,
    $app_font_size_3
  );
}
.card-pond-performance {
  // variables
  $high-color: #33a02c;
  $low-color: #e31a1c;
  $normal-color: #233a73;
  // styling
  .page_loading {
    @include responsiveProperty(height, 40vh, 40vh, 40vh);
    width: 100%;
    padding: 0px;
    margin: 0px;
    background-color: white;
    text-align: center;
  }

  &.er-card.er-card--secondary .el-card__body {
    padding: 5px 20px 10px 8px;
  }
  .card__header {
    display: flex;
    align-items: center;
    line-height: 2;
  }

  .legend-row {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    flex-direction: row;
    padding: 2.5px;
    .legend-row__item {
      margin-right: 10px;
      height: 20px;
      display: inline-flex;
      align-items: center;
      flex-direction: row;
      .color-indicator {
        @include responsiveProperty(
          width,
          $app_font_size_small,
          $app_font_size_1,
          $app_font_size_2
        );
        @include responsiveProperty(
          height,
          $app_font_size_small,
          $app_font_size_1,
          $app_font_size_2
        );
        margin-right: 5px;
        border-radius: 3px;
      }
      .legend-row__label {
        @include responsiveProperty(
          font-size,
          $app_font_size_small,
          $app_font_size_1,
          $app_font_size_2
        );
        font-weight: 400;
      }
    }
  }
  .color-indicator {
    &.high {
      background-color: $high-color;
    }
    &.low {
      background-color: $low-color;
    }
    &.normal {
      background-color: $normal-color;
    }
  }
  .low-cell {
    .cell {
      color: $low-color;
    }
  }
  .high-cell {
    .cell {
      color: $high-color;
    }
  }
  .normal-cell {
    color: $normal-color;
  }
  .blue-row-table {
    $table-row-box-shadow: #e3d1d1;
    $table-row-border-color: #b3b3b3;
    $table-header-cell-font-color: #233a73;

    &.el-table {
      width: 100%;
    }
    &.el-table--enable-row-hover .el-table__body tr:hover > td {
      background-color: unset;
    }
    .row-column-indicator {
      display: grid;
      height: 50%;
      @include component-font-size;
      grid-template-rows: repeat(2, 50% [col-start]);
      grid-template-columns: repeat(2, 50% [col-start]);
      align-items: start;

      & > div:nth-child(1),
      & > div:nth-child(2) {
        border-bottom: 1.5px solid #777777;
      }
      & > div:nth-child(1),
      & > div:nth-child(3) {
        border-right: 1.5px solid #777777;
      }

      & > div:nth-child(3):after,
      & > div:nth-child(2):after {
        content: "";
        position: absolute;
        display: inline-block;
        width: 8px;
        height: 8px;
        border-top: 1.5px solid #777777;
        border-left: 1.5px solid #777777;
        border-radius: 2px;
      }

      & > div:nth-child(3):after {
        transform: rotate(225deg);
        @include responsiveProperty(right, 45%, 46.25%, 48%);
        bottom: 0px;
        @include responsiveProperty(margin, 1.5px 1px, 1.5px 0px, 1.5px 0px);
      }
      & > div:nth-child(2):after {
        // right: 10px
        @include responsiveProperty(top, 50%, 50%, 50%);
        @include responsiveProperty(right, 1px, 0.5px, 0.5px);

        @include responsiveProperty(margin-top, -4.5px, -4.5px, -4.5px);
        transform: rotate(135deg);
      }
    }
    .blue-row-table__header-row {
      background-color: white;

      th {
        padding: 1px 0px;

        .cell {
          font-weight: 500;
        }
      }
      th:first-child {
        border-right: 1px solid rgba(0, 0, 0, 0.08);
      }
      th:not(:first-child) {
        color: $table-header-cell-font-color;
        background: #f8f8f8;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        .cell {
          display: inline-flex;
          flex-direction: row;
          justify-content: center;
          line-height: 1;

          @include component-font-size;
          .title-section {
            display: flex;
            flex-direction: row;
            padding: 1px;
          }
          .title-container {
            // display: inline-block;
            flex-direction: column;
            align-items: flex-end;
            display: flex;
            padding-left: 5px;
          }
          .symbol-holder {
            margin-right: 0px;
          }
          div.title {
            text-align: initial;
          }
          div.sub-title {
            text-align: left;
          }
          .sub-title {
            @include component-font-size;
            color: #508ff4;
          }
        }
      }
      th:last-child {
        border-right: 1px solid rgba(0, 0, 0, 0.15);
      }
    }

    .blue-row-table__row {
      td .cell {
        font-weight: 600;
        @include component-font-size;
        @include responsiveProperty(line-height, 1.65, 2.55, 3);
      }
      //1.9

      td:first-child {
        border-bottom: unset;
        background-color: #f8f8f8 !important;
        border-right: 1px solid rgba(0, 0, 0, 0.15);
        // box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.15);
        .cell {
          font-weight: 500;
          color: #233a73;
        }
      }
      &:not(:nth-child(2n)) {
        background-color: rgba(237, 244, 254, 0.8);
      }
      td:not(:nth-child(1)) {
        .cell {
          .high {
            color: $high-color;
          }
          .low {
            color: $low-color;
          }
          .normal {
            color: $normal-color;
          }
        }
      }
    }

    .el-table__body,
    .el-table__header {
      padding-left: 5px;
    }
    .el-table__header-wrapper,
    .el-table__body-wrapper {
      margin-right: 5px;
      margin-left: 5px;
    }
    &.el-table::before {
      margin-left: 12px;
    }
  }
  .el-table th > .cell {
    padding: 0px;
    line-height: 120%;
  }
}
</style>
